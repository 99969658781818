<template>
    <div class="cycle-odds">
        <el-card>
            <div slot="header" class="clearfix">
                <span>周期胜率</span>
                <date-range style="float: right" v-on:change="getData"></date-range>
            </div>
            <h-chart ref="chart" id="chart" :options="quantityOptions"></h-chart>
            <div class="blackboard">
                <p class="star-level"><span>指标星级：</span>
                    <el-rate v-model="starLevel" disabled></el-rate>
                </p>
                <p>指标释义：高频：持仓时间 ≦ 5min；日内：5min &lt; 持仓时间 ≦ 1day；短线：1day &lt; 持仓时间 ≦ 5day；中线：5day &lt; 持仓时间 ≦
                    15day；长线：持仓时间 >
                    15day。</p>
                <p>
                    如何使用：该指标为“七尾狐”独创，可全面分析账户的交易频率和持仓时间长短。从持仓周期的饼形图中，可分析自己账户不同周期单子的成交额占比，了解自己的交易风格。同时结合不同周期下的盈亏情况和胜率情况，了解自己所擅长的交易频率和周期，建议多做自己擅长的周期。该指标非常重要，推荐每日关注。</p>
            </div>
        </el-card>
    </div>
</template>

<script>
  import HChart from '@/components/HChart.vue'
  import DateRange from '@/components/DateRange.vue'
  import {Loading} from "element-ui";
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "CycleOdds",
    data () {
      return {
        starLevel: 5,
        quantityOptions: {
          credits: {
            enabled: false
          },
          chart: {
            renderTo: 'container',
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 5,
              beta: 5,
              depth: 30,
              viewDistance: 90
            },
            backgroundColor: null,
            plotBackgroundColor: null
          },
          title: false,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.2f}%</b>'
          },
          colors: ['#EB3E41'],
          xAxis: {
            categories: ["高频", "日内", "短线", "中线", "长线"]
          },
          yAxis: {
            title: false,
          },
          series: [],
        }
      }
    },
    components: {
      HChart,
      DateRange
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.h-chart'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('cycleOdds', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          let f1s = []
          for (const key in resData) {
            f1s.push(resData[key]*100)
          }
          this.quantityOptions.series = [{
            name: '周期胜率',
            data: f1s
          }];
          this.$refs.chart.redraw();
          loadingInstance.close();
        }
      },
    },
    mounted () {
      this.getData();
    }
  }
</script>

<style lang="scss">
    .cycle-odds {
        .el-card {
            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }

            .chart {
                margin-bottom: 20px;
                background-image: url("../../assets/watermark.png");
                background-repeat: no-repeat;
                background-size: 269px 150px;
                background-position: center;
            }
        }
    }
</style>
